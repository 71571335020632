import { UseFormTrigger } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "~/patients/router";
import { useOnBoardingPatientStore } from "~/patients/stores";
import {
  ActivePatientSchemaType,
  SignUpPatientSchemaType,
  SignUpTokenData,
} from "~/patients/utils";
import { useSubmitActivePatientSignUp } from "./useSubmitActivePatientSignUp";

interface useSignUpActivePatientProps {
  values: ActivePatientSchemaType;
  trigger: UseFormTrigger<SignUpPatientSchemaType>;
  signUpTokenData?: SignUpTokenData;
}
export const useSignUpActivePatient = ({
  values,
  trigger,
  signUpTokenData,
}: useSignUpActivePatientProps) => {
  const { setPatient, onBoardingStep, setOnBoardingStep } =
    useOnBoardingPatientStore();

  const navigate = useNavigate();

  const { onSubmitSignUp, isPending } = useSubmitActivePatientSignUp({
    values,
    trigger,
  });

  const onBack = () => {
    setOnBoardingStep(onBoardingStep - 1);
  };

  const updatePatientData = () => {
    try {
      const patientEmail = signUpTokenData?.user_email;
      if (!patientEmail) throw new Error("Invalid patient email");

      setPatient({ ...values, email: patientEmail });
      setOnBoardingStep(onBoardingStep + 1);
    } catch {
      navigate(ROUTES.notFound);
    }
  };

  const onNext = async (schemaName: keyof ActivePatientSchemaType) => {
    const isValid = await trigger(schemaName);
    if (isValid) {
      updatePatientData();
    }
  };

  return {
    onNext,
    onBack,
    isPending,
    onSubmitSignUp,
  };
};
