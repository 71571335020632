import { INTAKE_QUESTION_TYPES } from "~/common/utils/consultations/intake";
import {
  CompletedIndicatedTakingMedicationsAnswer,
  CompletedIntakeAnswer,
  CompletedIntakeSymptomsAnswer,
  CompletedListHospitalsAnswer,
  CompletedRadioWithDateAnswer,
  CompletedRadioWithTextAnswer,
  CompletedYesNoNAWithAudioAnswer,
  HighschoolQuestionData,
  IndicatedTakingMedicationsQuestionData,
  InstructionsQuestionData,
  IntakeQuestionAnswer,
  IntakeQuestionData,
  IntakeSymptomsQuestionData,
  ListHospitalsQuestionData,
  RadioQuestionWithDateData,
  RadioQuestionWithTextData,
  RadioWithTertiaryEducationQuestionData,
  TherapistInThePastQuestionData,
  YesNoNAWithAudioOnYesQuestionData,
} from "~/common/utils/consultations/intake/interfaces";
import {
  AudioQuestionData,
  CompletedAudioAnswer,
  CompletedListJobsAnswer,
  CompletedMultiCheckAnswer,
  CompletedRadioAnswer,
  CompletedTextAnswer,
  DateQuestionData,
  ListJobsQuestionData,
  MultiCheckQuestionData,
  RadioGroupCompletedAnswer,
  RadioGroupQuestionData,
  RadioQuestionData,
  TextQuestionData,
} from "~/common/utils/consultations/shared";
import {
  HighschoolForm,
  IndicatedTakingMedicationsForm,
  IntakeAudioForm,
  ListHospitalsForm,
  ListJobForm,
  RadioWithDateForm,
  RadioWithTextForm,
  RadioYesNoNaAudioForm,
  TherapistInThePastForm,
} from "~/patients/components/consultations/intake/forms";
import {
  DateForm,
  MultiCheckForm,
  RadioForm,
  TextForm,
} from "~/patients/components/consultations/shared";
import { DateType } from "~/patients/utils";
import {
  HighschoolType,
  RadioWithTertiaryEducationType,
  TherapistInThePastType,
} from "~/patients/utils/consultations/intakes";
import { IntakeSymptomsForm } from "../forms";
import { RadioGroupForm } from "../forms/RadioGroupForm";
import { RadioWithTertiaryEducationForm } from "../forms/RadioWithTertiaryEducationForm";
import { InstructionsScreen } from "../InstructionsScreen";

interface IntakeQuestionRendererProps {
  handleGoBack: () => void;
  question: IntakeQuestionData;
  onSubmit: (answer: IntakeQuestionAnswer) => void;
  isSubmitting?: boolean;
  answer?: CompletedIntakeAnswer;
  disableBack?: boolean;
  currentQuestionOptions?: string[];
  isOptional: boolean;
}
export const IntakeQuestionRenderer = ({
  question,
  answer,
  handleGoBack,
  onSubmit,
  isSubmitting = false,
  disableBack = false,
  currentQuestionOptions,
  isOptional,
}: IntakeQuestionRendererProps) => {
  const formContent = {
    [INTAKE_QUESTION_TYPES.text]: (
      <TextForm
        question={question as TextQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswer={answer as CompletedTextAnswer}
        isOptional={isOptional}
      />
    ),
    [INTAKE_QUESTION_TYPES.multi_check]: (
      <MultiCheckForm
        question={question as MultiCheckQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswer={answer as CompletedMultiCheckAnswer}
        currentQuestionOptions={currentQuestionOptions}
      />
    ),
    [INTAKE_QUESTION_TYPES.audio]: (
      <IntakeAudioForm
        question={question as AudioQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        completedAnswer={answer as CompletedAudioAnswer}
        disableBack={disableBack}
        isOptional={isOptional}
      />
    ),
    [INTAKE_QUESTION_TYPES.jobs_you_have_held]: (
      <ListJobForm
        question={question as ListJobsQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        completedAnswers={answer as CompletedListJobsAnswer}
        disableBack={disableBack}
      />
    ),
    [INTAKE_QUESTION_TYPES.medical_or_psychiatric_hospitalization_please_provide_more_details]:
      (
        <ListHospitalsForm
          question={question as ListHospitalsQuestionData}
          handleGoBack={handleGoBack}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          completedAnswers={answer as CompletedListHospitalsAnswer}
          disableBack={disableBack}
        />
      ),
    [INTAKE_QUESTION_TYPES.you_indicated_you_have_seen_a_therapist]: (
      <TherapistInThePastForm
        question={question as TherapistInThePastQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswers={answer as TherapistInThePastType}
      />
    ),
    [INTAKE_QUESTION_TYPES.radio]: (
      <RadioForm
        question={question as RadioQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswer={answer as CompletedRadioAnswer}
      />
    ),
    [INTAKE_QUESTION_TYPES.radio_with_tertiary_education]: (
      <RadioWithTertiaryEducationForm
        question={question as RadioWithTertiaryEducationQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswer={answer as RadioWithTertiaryEducationType}
      />
    ),
    [INTAKE_QUESTION_TYPES.radio_with_text]: (
      <RadioWithTextForm
        question={question as RadioQuestionWithTextData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswer={answer as CompletedRadioWithTextAnswer}
      />
    ),
    [INTAKE_QUESTION_TYPES.radio_with_date]: (
      <RadioWithDateForm
        question={question as RadioQuestionWithDateData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswer={answer as CompletedRadioWithDateAnswer}
      />
    ),
    [INTAKE_QUESTION_TYPES.radio_with_optional_text]: (
      <RadioWithTextForm
        question={question as RadioQuestionWithTextData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswer={answer as CompletedRadioWithTextAnswer}
      />
    ),
    [INTAKE_QUESTION_TYPES.radio_with_highschool]: (
      <HighschoolForm
        question={question as HighschoolQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswer={answer as HighschoolType}
      />
    ),
    [INTAKE_QUESTION_TYPES.you_indicated_taking_medications]: (
      <IndicatedTakingMedicationsForm
        question={question as IndicatedTakingMedicationsQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        completedAnswer={answer as CompletedIndicatedTakingMedicationsAnswer}
        disableBack={disableBack}
        currentQuestionOptions={currentQuestionOptions}
      />
    ),
    [INTAKE_QUESTION_TYPES.date]: (
      <DateForm
        question={question as DateQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        completedAnswer={answer as DateType}
        disableBack={disableBack}
      />
    ),
    [INTAKE_QUESTION_TYPES.instructions]: (
      <InstructionsScreen
        question={question as InstructionsQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
      />
    ),
    [INTAKE_QUESTION_TYPES.symptoms_experienced_multi_select]: (
      <IntakeSymptomsForm
        question={question as IntakeSymptomsQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        disableBack={disableBack}
        completedAnswer={answer as CompletedIntakeSymptomsAnswer}
      />
    ),
    [INTAKE_QUESTION_TYPES.yes_no_na_with_audio_on_yes]: (
      <RadioYesNoNaAudioForm
        question={question as YesNoNAWithAudioOnYesQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        completedAnswer={answer as CompletedYesNoNAWithAudioAnswer}
        disableBack={disableBack}
      />
    ),
    [INTAKE_QUESTION_TYPES.radio_group]: (
      <RadioGroupForm
        question={question as RadioGroupQuestionData}
        handleGoBack={handleGoBack}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        completedAnswer={answer as RadioGroupCompletedAnswer}
        disableBack={disableBack}
      />
    ),
  };

  return formContent[question.type];
};
