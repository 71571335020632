import { PropsWithChildren } from "react";
import * as jose from "jose";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { Styled } from "~/common/utils";
import { DATA_TOKEN } from "../constants";
import { useGetCurrentPatient } from "../hooks/queries/useGetCurrentPatient";

interface ProvidersMessageProps extends PropsWithChildren, Styled {
  greeting: string;
}

export const ProvidersMessage = ({
  children,
  greeting,
  className,
}: ProvidersMessageProps) => {
  const [searchParams] = useSearchParams();
  const dataToken = searchParams.get(DATA_TOKEN);

  const { data: user } = useGetCurrentPatient({ enabled: !dataToken });
  let imageSrc = user?.provider.profile_picture_path;
  let teamMember = user?.provider.signature_name;

  if (!user && dataToken) {
    const dataJWT = jose.decodeJwt(dataToken);
    imageSrc = dataJWT.provider_image as string;
    teamMember = dataJWT.provider_name as string;
  }

  return (
    <div
      className={twMerge(
        "flex min-h-40 w-full flex-col gap-4 break-all rounded-2xl bg-slate-50 px-4 py-4 text-gray-70 sm:w-3/5 md:text-base xl:w-3/4",
        className,
      )}
    >
      {children}

      <div className="flex items-center justify-between">
        <p>
          {greeting} <br />
          <strong className="text-base font-semibold">{teamMember}</strong>
        </p>

        {imageSrc && (
          <img
            className="w-1/4 rounded-full"
            src={imageSrc}
            alt="ProfilePicture"
          />
        )}
      </div>
    </div>
  );
};
