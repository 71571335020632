"use client";

import { useState } from "react";

import { LoadingState } from "~/common/components";
import NavMenu from "~/common/components/ui/NavMenu";
import Account from "~/patients/components/account/Account";
import { useGetCurrentPatient } from "../hooks/queries/useGetCurrentPatient";
import { useUiStore } from "../stores/useUiStore";

const NAV_MENU_OPTION = {
  account: "account",
} as const;

type NavMenuOptionsType = keyof typeof NAV_MENU_OPTION;

export const Settings = () => {
  const { setSideBarOpen } = useUiStore();
  const [selectedMenuValue, setSelectedMenuValue] =
    useState<NavMenuOptionsType>(NAV_MENU_OPTION.account);

  const menuOptions = [
    {
      label: "Account",
      onClick: () => {
        setSelectedMenuValue(NAV_MENU_OPTION.account);
      },
      value: NAV_MENU_OPTION.account,
      enabled: true,
    },
  ];

  const { data: currentPatient, isLoading } = useGetCurrentPatient();

  return (
    <div className="flex h-screen-calculated w-full flex-col gap-6 text-sm md:gap-8">
      <NavMenu
        title="Settings"
        selectedMenuValue={selectedMenuValue}
        menuOptions={menuOptions}
        handleOpenSideBar={() => setSideBarOpen(true)}
      />
      {isLoading && <LoadingState className="h-screen-calculated" />}
      {selectedMenuValue === NAV_MENU_OPTION.account && currentPatient && (
        <Account currentPatient={currentPatient} />
      )}
    </div>
  );
};
