import { StatesTypes } from "~/common/constants/usaStates";
import { AccountSchemaType } from "../components/account/Account";
import { PRONOUNS } from "../constants/constants";
import {
  RELATIONSHIPS,
  relationshipsOptions,
} from "../constants/relationships";
import { PronounsSchemaType } from "../utils";
import { pronounsOptions } from "../utils/getPronouns";
import {
  CurrentPatientData,
  PronounsOptions,
  UpdateCurrentPatientData,
} from "../utils/types";

export const useFormatHook = () => {
  const formatPhoneNumber = (value: string) => {
    const phoneNumber = value.replace(/[^\d]/g, "");
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6,
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const pronounsAsObject = (data: PronounsSchemaType) => {
    let stringPronouns = data?.pronouns;
    if (stringPronouns === PRONOUNS.other && data?.customPronouns) {
      stringPronouns = data.customPronouns;
    }
    if (stringPronouns) {
      const [subject, object, possessive] = stringPronouns.split("/");
      return { subject, object, possessive };
    } else {
      return null;
    }
  };

  const defaultAddress = {
    street: "",
    apartment: "",
    city: "",
    state: "Alabama" as StatesTypes,
    zipcode: "",
  };

  const formatPronouns = (pronouns?: PronounsOptions | null) => {
    const pronounsValues = Object.values(pronouns ?? {}).join("/");
    const defaultPronouns =
      pronouns &&
      pronounsValues.length > 0 &&
      pronounsOptions.some((pronoun) => pronoun.value === pronounsValues)
        ? pronounsValues
        : PRONOUNS.other;
    const customPronouns =
      defaultPronouns === PRONOUNS.other ? pronounsValues : "";

    return { pronouns: defaultPronouns, customPronouns: customPronouns ?? "" };
  };

  const formatEmergencyContact = (data: CurrentPatientData) => {
    const relationship = data.emergency_contacts?.[0]?.relationship;

    const contactRelationship = relationshipsOptions.some(
      (relation) => relation.value === relationship,
    )
      ? relationship
      : RELATIONSHIPS.OTHER;

    const customRelationship =
      contactRelationship === RELATIONSHIPS.OTHER ? relationship : "";

    return data.emergency_contacts.length > 0
      ? [
          {
            ...data.emergency_contacts?.[0],
            last_name: data.emergency_contacts?.[0].last_name ?? "",
            phone_number:
              data.emergency_contacts?.[0].phone_number &&
              formatPhoneNumber(data.emergency_contacts?.[0].phone_number),
            relationship: contactRelationship,
            customRelationship: customRelationship,
          },
        ]
      : [];
  };

  const transformedPatientData = (
    data: UpdateCurrentPatientData | CurrentPatientData,
  ): AccountSchemaType => ({
    ...data,
    phone_number:
      (data.phone_number && formatPhoneNumber(data.phone_number)) ?? "",
    pronouns: formatPronouns(data.pronouns),
    emergency_contacts: formatEmergencyContact(data),
    addresses: [data?.addresses?.[0] ?? defaultAddress],
    birthday: data.birthday ?? "",
  });

  return { formatPhoneNumber, transformedPatientData, pronounsAsObject };
};
