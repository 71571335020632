import {
  Control,
  FieldPath,
  FieldValues,
  UseFormRegisterReturn,
  UseFormResetField,
} from "react-hook-form";

import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import { pronounsOptionsWithOther, PronounsSchemaType } from "~/patients/utils";
import { FormErrors } from "~/patients/utils/types/forms";
import SignUpFormLayout from "../SignUpFormLayout";
import SignUpProgress from "../SignUpProgress";

interface PatientPronounsFormProps<T extends FieldValues> {
  handleGoBack: () => void;
  totalSteps: number;
  register: (data: keyof PronounsSchemaType) => UseFormRegisterReturn<string>;
  onNext: () => Promise<void>;
  errors: FormErrors<PronounsSchemaType> | undefined;
  otherPronouns: boolean;
  step: number;
  resetField: UseFormResetField<PronounsSchemaType>;
  pronounsKey: FieldPath<T>;
  control?: Control<T>;
}

const PatientPronounsForm = <T extends FieldValues>({
  onNext,
  register,
  handleGoBack,
  totalSteps,
  errors,
  control,
  otherPronouns,
  resetField,
  pronounsKey,
  step,
}: PatientPronounsFormProps<T>) => {
  const pronouns = pronounsOptionsWithOther;

  return (
    <>
      <SignUpProgress step={step} totalSteps={totalSteps} />
      <SignUpFormLayout
        title="Your pronouns"
        handleGoBack={handleGoBack}
        handleData={onNext}
      >
        <div className="flex flex-col gap-2 md:flex-row md:justify-between md:gap-4">
          <Select
            error={errors?.pronouns?.message}
            label="Pronouns"
            placeholder={"Select pronouns"}
            id={pronounsKey}
            options={pronouns}
            control={control}
            onChange={() => resetField("customPronouns")}
            containerClassName="gap-2"
          />
          {otherPronouns && (
            <div className="flex h-full w-full flex-col items-end">
              <div className="hidden h-7 md:block" />
              <Input
                id="customPronouns"
                placeholder="She/Her/Hers"
                {...register("customPronouns")}
                error={errors?.customPronouns?.message}
              />
            </div>
          )}
        </div>
      </SignUpFormLayout>
    </>
  );
};

export default PatientPronounsForm;
