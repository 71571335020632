import { PatientPharmacy, PharmacyQueryType } from "~/common/types/pharmacies";
import { api, PaginatedServiceResponse } from "./axios";

export const getPatientPharmacies = async () => {
  const { data } = await api.get<PaginatedServiceResponse<PharmacyQueryType[]>>(
    `/v1/patients/pharmacies`,
    {
      params: {
        page_size: 100,
        page: 1,
        order_by: "created_at",
      },
    },
  );

  return data.data;
};

export const deleteMyPharmacy = async (pharmacyId: string) => {
  const { data } = await api.delete<void>(
    `/v1/patients/pharmacies/${pharmacyId}`,
  );

  return { data };
};

export const createMyPharmacy = async (pharmacy: PatientPharmacy) => {
  const { data } = await api.post<PharmacyQueryType>(
    `/v1/patients/pharmacies`,
    pharmacy,
  );

  return { data };
};

export const updateMyPharmacy = async (
  pharmacy: PatientPharmacy,
  pharmacyId: string,
) => {
  const { data } = await api.put<void>(
    `/v1/patients/pharmacies/${pharmacyId}`,
    pharmacy,
  );

  return { data };
};

export const setDefaultPharmacy = async (pharmacyId: string) => {
  const { data } = await api.post<void>(
    `/v1/patients/pharmacies/${pharmacyId}/set-default`,
    {},
  );

  return { data };
};
