import { twMerge } from "tailwind-merge";

import { TrashIcon } from "~/common/components/icons";
import { PencilIcon } from "~/common/components/icons/PencilIcon";
import { PharmacyBoxLayout } from "~/common/components/PharmacyBoxLayout";
import { Tag } from "~/common/components/Tag";
import { PharmacyQueryType } from "~/common/types/pharmacies";
import { useSetDefaultPharmacy } from "~/patients/hooks/queries/pharmacy/useSetDefaultPharmacy";
import { useFormatHook } from "~/patients/hooks/useFormatHook";
import { IconWrapper } from "~/patients/ui";

interface PharmacyCardProps {
  patientPharmacy: PharmacyQueryType;
  setPharmacyIdToDelete: (id: string) => void;
  setPharmacyForm: (pharmacy: PharmacyQueryType) => void;
}

export const PharmacyCard = ({
  patientPharmacy,
  setPharmacyIdToDelete,
  setPharmacyForm,
}: PharmacyCardProps) => {
  const { pharmacy } = patientPharmacy;
  const { address } = pharmacy;

  const { setDefaultPharmacyMutation, isPending } = useSetDefaultPharmacy();
  const { formatPhoneNumber } = useFormatHook();

  return (
    <PharmacyBoxLayout>
      <div className="truncate">
        <h4 className="font-semibold">{pharmacy.name}</h4>
        <p>{address.street}</p>
        <p>{address.city}</p>
        <p>{address.state}</p>
        <p>{formatPhoneNumber(pharmacy.phone_number)}</p>
      </div>

      <div className="flex items-center justify-between">
        {patientPharmacy.is_default ? (
          <Tag className="bg-primary-100 text-primary-600">Default</Tag>
        ) : (
          <button
            type="button"
            className={twMerge(
              "font-medium text-primary-600",
              isPending && "text-gray-70",
            )}
            disabled={isPending}
            onClick={() => setDefaultPharmacyMutation(patientPharmacy.id)}
          >
            Set as default
          </button>
        )}
        <div className="flex items-center justify-between gap-3">
          <button
            type="button"
            onClick={() => setPharmacyForm(patientPharmacy)}
          >
            <IconWrapper className="h-full w-3">
              <PencilIcon className="text-gray-20" />
            </IconWrapper>
          </button>

          <button
            type="button"
            onClick={() => setPharmacyIdToDelete(patientPharmacy.id)}
          >
            <IconWrapper className="h-full w-3">
              <TrashIcon className="text-gray-20" />
            </IconWrapper>
          </button>
        </div>
      </div>
    </PharmacyBoxLayout>
  );
};
