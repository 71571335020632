import { useNavigate } from "react-router-dom";

import { NotFound } from "~/common/assets";
import { DescriptiveErrorLayout } from "~/common/components";
import { useGetCurrentPatient } from "../hooks/queries/useGetCurrentPatient";
import { getHomePath } from "../utils";

export const NotFoundScreen = () => {
  const { data: user } = useGetCurrentPatient();
  const navigate = useNavigate();
  const homePath = getHomePath(user?.status);
  const buttonText = user?.status ? "Back to home" : "Go to Login";

  return (
    <DescriptiveErrorLayout
      errorType="404"
      title="Page not found"
      description="Sorry, the page you are looking for does not exist."
      handleBackToHome={() => navigate(homePath)}
      buttonText={buttonText}
    >
      <img src={NotFound} alt="error 404" />
    </DescriptiveErrorLayout>
  );
};
