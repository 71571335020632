import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";

import DatePicker from "~/common/components/ui/DatePicker";
import {
  getTodayDate,
  INTAKE_MULTI_CHECK_VALUES,
  replaceUnderscores,
} from "~/common/utils";
import {
  CompletedRadioWithDateAnswer,
  RadioQuestionWithDateData,
  RadioWithDateAnswer,
} from "~/common/utils/consultations/intake/interfaces";
import { ConsultationQuestionsLayout } from "~/patients/components";
import RadioButtonOption from "~/patients/components/ui/RadioButtonOption";
import {
  radioWithDateAnswerSchema,
  RadioWithDateAnswerType,
} from "~/patients/utils/consultations/intakes";

export interface RadioWithDateFormProps {
  question: RadioQuestionWithDateData;
  handleGoBack: () => void;
  onSubmit: (answer: RadioWithDateAnswer) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswer: CompletedRadioWithDateAnswer;
}

export const RadioWithDateForm = ({
  question,
  handleGoBack,
  onSubmit,
  isSubmitting,
  disableBack,
  completedAnswer,
}: RadioWithDateFormProps) => {
  const { options, title } = question;
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm<RadioWithDateAnswerType>({
    defaultValues: {
      answer: {
        ...completedAnswer,
      },
    },
    resolver: zodResolver(radioWithDateAnswerSchema),
  });

  const saveAnswers: SubmitHandler<RadioWithDateAnswerType> = (data) => {
    onSubmit(data);
  };

  const valueSelected = watch("answer.value");
  const optionSelected = options.find(({ value }) => value === valueSelected);

  useEffect(() => {
    if (optionSelected?.value === INTAKE_MULTI_CHECK_VALUES.no) {
      reset({
        answer: { value: INTAKE_MULTI_CHECK_VALUES.no },
      });
    }
    if (!errors.answer?.date) return;
    clearErrors("answer.date");
  }, [optionSelected]);

  const today = getTodayDate();

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={!valueSelected}
      errors={errors.answer?.message}
      isSubmitting={isSubmitting}
      title={title}
      disableBack={disableBack}
    >
      {options.map(({ value }) => (
        <RadioButtonOption
          key={value}
          label={replaceUnderscores(value)}
          value={value}
          isSelected={valueSelected === value}
          {...register("answer.value")}
        />
      ))}
      {optionSelected?.asksExtraInfo && (
        <>
          <DatePicker
            id="date"
            label="Date"
            {...register("answer.date")}
            max={today}
            error={errors?.answer?.date?.message}
          />
        </>
      )}
    </ConsultationQuestionsLayout>
  );
};
