import { useRef, useState } from "react";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { UseFormReset, UseFormSetValue } from "react-hook-form";
import { toast } from "react-toastify";

import { useRecordingStore } from "~/common/stores/useRecordingStore";
import {
  CompletedAudioAnswer,
  ConsultationAudioAnswer,
} from "~/common/utils/consultations/shared";
import { AudioRecorderHandle } from "~/patients/components";

export const useHandleConsultationAudio = (
  handleGoBack: () => void,
  setValue: UseFormSetValue<ConsultationAudioAnswer>,
  reset: UseFormReset<ConsultationAudioAnswer>,
  deleteAudioMutation: UseMutateAsyncFunction<null, Error, void, unknown>,
  duration: number,
  completedAnswer?: CompletedAudioAnswer,
  isOptional?: boolean,
) => {
  const [audioFile, setAudioFile] = useState<File>();
  const audioRecorderRef = useRef<AudioRecorderHandle>(null);
  const { userRecording } = useRecordingStore();

  const deleteRecordingAndGoBack = () => {
    if (audioRecorderRef.current?.isRecording) {
      audioRecorderRef.current.stopRecording();
      audioRecorderRef.current.handleDeleteRecording();
    }
    handleGoBack();
  };

  const setRecordedAudio = (audioBlob: Blob, duration: number) => {
    const file = new File([audioBlob], "audio.wav", {
      type: audioBlob.type,
    });
    if (file.size === 0) {
      toast.error("We shoot trouble saving your recording, please try again!");
      return;
    }
    setAudioFile(file);
    setValue("answer.duration", duration);
  };

  const handleDeleteAudio = async () => {
    try {
      !!completedAnswer?.presigned_url && (await deleteAudioMutation());

      setAudioFile(undefined);
      reset({ answer: { duration: undefined } });
    } catch (error) {
      toast.error(
        "There was a problem deleting the audio. If the issue persists, please reload the page.",
      );
    }
  };

  const disableNextButton = (!duration && !isOptional) || userRecording;
  const submitButtonLabel =
    isOptional && !userRecording && !audioFile && !duration
      ? "Skip"
      : undefined;

  return {
    audioFile,
    audioRecorderRef,
    deleteRecordingAndGoBack,
    setRecordedAudio,
    handleDeleteAudio,
    disableNextButton,
    submitButtonLabel,
  };
};
