import type { Control, UseFormRegisterReturn } from "react-hook-form";
import { z } from "zod";

import { CardInputsContainer } from "~/common/components";
import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import { usaStatesOptions } from "~/common/constants/usaStates";
import { patientAddressSchema } from "~/patients/utils/PatientSchemas";
import { FormErrors } from "~/patients/utils/types";
import type { AccountSchemaType } from "./Account";

export type AddressesSchemaType = z.infer<typeof patientAddressSchema>;
interface AddressProps {
  register: (data: keyof AddressesSchemaType) => UseFormRegisterReturn<string>;
  control?: Control<AccountSchemaType>;
  errors: FormErrors<AddressesSchemaType> | undefined;
}

const Address = ({ register, control, errors }: AddressProps) => {
  return (
    <CardInputsContainer title="Address">
      <div className="flex w-full flex-col gap-4">
        <Input
          id="street"
          label="Street number and name"
          {...register("street")}
          error={errors?.street?.message}
        />
        <Input
          id="apartment"
          label="Apartment, suite, etc."
          {...register("apartment")}
          error={errors?.apartment?.message}
        />
        <div className="flex w-full flex-col gap-4 md:flex-row md:gap-2">
          <Input
            id="city"
            label="City"
            {...register("city")}
            error={errors?.city?.message}
          />
          <Select
            error={errors?.state?.message}
            label="State"
            placeholder={"Select State"}
            id="addresses.0.state"
            options={usaStatesOptions}
            control={control}
            buttonClassName="py-3"
            containerClassName="gap-2"
          />
          <Input
            id="zipcode"
            label="Zip code"
            {...register("zipcode")}
            error={errors?.zipcode?.message}
          />
        </div>
      </div>
    </CardInputsContainer>
  );
};

export default Address;
