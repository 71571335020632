import { PropsWithChildren } from "react";
import { UseFormRegister } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import QuestionaryCheckBox from "~/common/components/ui/QuestionaryCheckBox";
import {
  SymptomBaseForm,
  SymptomsFormValues,
} from "~/patients/utils/consultations/intake";

interface CheckSymptomProps extends PropsWithChildren {
  symptom: SymptomBaseForm;
  register: UseFormRegister<SymptomsFormValues>;
  isParentSelected: boolean;
  isSelected: boolean;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isNestedParentWithTextChildren?: boolean;
}

export const CheckSymptom = ({
  symptom,
  register,
  children,
  isParentSelected,
  isNestedParentWithTextChildren,
  isSelected,
  handleCheckboxChange,
}: CheckSymptomProps) => {
  const { value, label } = symptom;
  return (
    <div className="flex flex-col">
      <div
        className={twMerge(
          "flex flex-col gap-2",
          isParentSelected &&
            !isNestedParentWithTextChildren &&
            "rounded-md border border-primary-500 bg-primary-100 pb-2",
        )}
      >
        <QuestionaryCheckBox
          checked={!!isSelected}
          id={value}
          labelClassName={twMerge(
            isParentSelected &&
              !isNestedParentWithTextChildren &&
              "rounded-t-md rounded-b-none border-x-0 border-t-0 border-b border-primary-500",
          )}
          {...register(value)}
          onChange={handleCheckboxChange}
        >
          {label}
        </QuestionaryCheckBox>
        {children}
      </div>
    </div>
  );
};
