import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";

import DatePicker from "~/common/components/ui/DatePicker";
import {
  getTodayDate,
  LAST_MENSTRUAL_CYCLE_QUESTION_TITLE,
} from "~/common/utils";
import { SCREENING_QUESTION_TYPES } from "~/common/utils/consultations/screening";
import {
  AgeQuestionData,
  DateQuestionData,
} from "~/common/utils/consultations/shared";
import { ConsultationQuestionsLayout } from "~/patients/components/consultations/shared";
import { dateAdultRequiredFormSchema } from "~/patients/utils";
import {
  dateFormSchema,
  DateFormType,
  DateType,
} from "~/patients/utils/consultations/shared";

export interface DateFormProps {
  question: DateQuestionData | AgeQuestionData;
  handleGoBack: () => void;
  onSubmit: (answer: DateFormType) => void;
  isSubmitting: boolean;
  disableBack: boolean;
  completedAnswer: DateType;
}

export const DateForm = ({
  question,
  onSubmit,
  handleGoBack,
  isSubmitting,
  disableBack,
  completedAnswer,
}: DateFormProps) => {
  const { title, type } = question;
  const isAgeQuestion = type === SCREENING_QUESTION_TYPES.age;

  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm<DateFormType>({
    defaultValues: {
      answer: completedAnswer,
    },
    resolver: zodResolver(
      isAgeQuestion ? dateAdultRequiredFormSchema : dateFormSchema,
    ),
  });

  const saveAnswers: SubmitHandler<DateFormType> = (data) => onSubmit(data);

  const date = watch("answer.date");
  const isNextDisabled = !date;

  const today = getTodayDate();
  const hasMaxRestriction =
    isAgeQuestion || title === LAST_MENSTRUAL_CYCLE_QUESTION_TITLE;

  return (
    <ConsultationQuestionsLayout
      handleNext={handleSubmit(saveAnswers)}
      handleBack={handleGoBack}
      disableNext={isNextDisabled}
      isSubmitting={isSubmitting}
      title={title}
      disableBack={disableBack}
    >
      <DatePicker
        id="date"
        label="Date"
        {...register("answer.date")}
        max={hasMaxRestriction ? today : undefined}
        error={errors?.answer?.date?.message}
      />
    </ConsultationQuestionsLayout>
  );
};
