import * as jose from "jose";
import { useNavigate, useSearchParams } from "react-router-dom";

import { DATA_TOKEN } from "~/patients/constants";
import { ROUTES } from "~/patients/router";
import { SignUpTokenData } from "~/patients/utils";

interface useValidateSignupNewPatientTokenProps {
  setProviderId: (providerId: string) => void;
}

interface useValidateSignupActivePatientTokenProps {
  setSignUpTokenData: (data: SignUpTokenData) => void;
}

export const useValidateSignupToken = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const validateSignUpNewPatientToken = ({
    setProviderId,
  }: useValidateSignupNewPatientTokenProps) => {
    try {
      const dataToken = searchParams.get(DATA_TOKEN);
      if (!dataToken) throw new Error("Missing token");

      const userJWT = jose.decodeJwt(dataToken);
      const providerId = userJWT.provider_id as string | undefined;

      if (!providerId) throw new Error("Invalid token");

      setProviderId(providerId);
    } catch {
      navigate(ROUTES.notFound);
    }
  };

  const validateSignUpActivePatientToken = ({
    setSignUpTokenData,
  }: useValidateSignupActivePatientTokenProps) => {
    try {
      const dataToken = searchParams.get(DATA_TOKEN);
      if (!dataToken) throw new Error("Missing token");
      const userJWT: SignUpTokenData = jose.decodeJwt(dataToken);

      const { user_email, user_name, provider_name, provider_image } = userJWT;

      if (!user_email || !user_name || !provider_name || !provider_image) {
        throw new Error("Invalid token");
      }

      setSignUpTokenData({ ...userJWT });
    } catch {
      navigate(ROUTES.notFound);
    }
  };

  return { validateSignUpNewPatientToken, validateSignUpActivePatientToken };
};
