import { useQueryClient } from "@tanstack/react-query";
import * as jose from "jose";
import { UseFormTrigger } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";

import { DATA_TOKEN } from "~/patients/constants";
import { useSendEmailVerification } from "~/patients/hooks/queries/useSendEmailVerification";
import { ROUTES } from "~/patients/router";
import { useOnBoardingNewPatientStore } from "~/patients/stores/useOnBoardingNewPatientStore";
import {
  NewPatientSchemaType,
  SignUpNewPatientDataRequest,
  SignUpPatientSchemaType,
} from "~/patients/utils";
import { useCreateNewPatient } from "./useCreateNewPatient";

interface useSubmitNewPatientSignUpProps {
  values: NewPatientSchemaType;
  trigger: UseFormTrigger<SignUpPatientSchemaType>;
}
export const useSubmitNewPatientSignUp = ({
  values,
  trigger,
}: useSubmitNewPatientSignUpProps) => {
  const { setNewPatient, setOnBoardingNewPatientStep } =
    useOnBoardingNewPatientStore();

  const [searchParams] = useSearchParams();
  const dataToken = searchParams.get(DATA_TOKEN);

  const { sendEmail } = useSendEmailVerification();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const onSignUpSuccess = () => {
    queryClient.clear();
    navigate(ROUTES.verifyEmail);
    setNewPatient();
    setOnBoardingNewPatientStep(0);
    sendEmail({ showSuccessMessage: false });
  };

  const { signUpMutation, isPending } = useCreateNewPatient(onSignUpSuccess);

  const onSubmitSignUp = async () => {
    try {
      const isValidData = await trigger();
      if (!isValidData) return;

      if (!dataToken) throw new Error("Missing token");

      const userJWT = jose.decodeJwt(dataToken);
      const providerId = userJWT.provider_id as string | undefined;

      if (!providerId) throw new Error("Invalid provider ID");

      const newPatient: SignUpNewPatientDataRequest = {
        ...values.phone_number,
        ...values.namesAndEmail,
        password: values.password.password,
        address: values.address,
        provider_id: providerId,
      };

      signUpMutation({ newPatient });
    } catch (error) {
      navigate(ROUTES.notFound);
    }
  };

  return {
    onSubmitSignUp,
    isPending,
  };
};
