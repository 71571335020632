import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { twMerge } from "tailwind-merge";

import Modal from "~/common/components/Modal";
import { Button } from "~/common/components/ui/button";
import Input from "~/common/components/ui/Input";
import Select from "~/common/components/ui/select/Select";
import { DIRECTIONS } from "~/common/constants";
import { usaStatesOptions } from "~/common/constants/usaStates";
import { PharmacyQueryType } from "~/common/types/pharmacies";
import { useGetPharmacyDefaultValues } from "~/patients/hooks/queries/pharmacy/useGetPharmacyDefaultValues";
import { useUpsertMyPharmacy } from "~/patients/hooks/queries/pharmacy/useUpsertPharmacy";
import {
  CreateNewPharmacyFormValuesType,
  createNewPharmacySchema,
} from "~/patients/utils";
import { formatPhoneNumberField } from "~/patients/utils/formatPhoneNumberField";
import CheckBox from "../../ui/CheckBox";

interface PharmacyModalProps {
  show: boolean;
  onClose: () => void;
  selectedPharmacy?: PharmacyQueryType;
}

export const PharmacyModalForm = ({
  show,
  onClose,
  selectedPharmacy,
}: PharmacyModalProps) => {
  const { getDefaultValues } = useGetPharmacyDefaultValues();

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    register,
  } = useForm<CreateNewPharmacyFormValuesType>({
    resolver: zodResolver(createNewPharmacySchema),
  });

  const handleCloseModal = () => {
    onClose();
    reset();
  };

  const { upsertPharmacy, isPending } = useUpsertMyPharmacy(handleCloseModal);

  useEffect(() => {
    reset(getDefaultValues(selectedPharmacy));
  }, [selectedPharmacy]);

  return (
    <Modal
      className="w-full md:w-1/2"
      title={selectedPharmacy ? "Edit pharmacy" : "Add new pharmacy"}
      show={show}
      onClose={handleCloseModal}
    >
      <form className="flex w-full flex-col gap-4">
        <Input
          id="name"
          label="Pharmacy name"
          {...register("pharmacy.name")}
          error={errors?.pharmacy?.name?.message}
        />

        <Input
          id="phoneNumber"
          label="Phone number"
          {...register("pharmacy.phone_number")}
          {...formatPhoneNumberField<CreateNewPharmacyFormValuesType>(
            "pharmacy.phone_number",
            register,
          )}
          error={errors?.pharmacy?.phone_number?.message}
        />

        <Input
          id="street"
          label="Street number and name"
          {...register("pharmacy.address.street")}
          error={errors?.pharmacy?.address?.street?.message}
        />

        <div className="flex flex-col gap-4 lg:flex-row">
          <Input
            id="city"
            label="City"
            {...register("pharmacy.address.city")}
            error={errors?.pharmacy?.address?.city?.message}
          />

          <Select
            error={errors?.pharmacy?.address?.state?.message}
            direction={DIRECTIONS.top}
            label="State"
            placeholder="Select state"
            id="pharmacy.address.state"
            options={usaStatesOptions}
            control={control}
            containerClassName="gap-2"
          />

          <Input
            id="zipcode"
            label="ZIP code"
            {...register("pharmacy.address.zipcode")}
            error={errors?.pharmacy?.address?.zipcode?.message}
          />
        </div>

        <div
          className={twMerge(
            "flex w-full flex-col items-center gap-4 lg:flex-row",
            selectedPharmacy && "justify-end",
          )}
        >
          {!selectedPharmacy && (
            <CheckBox id="is_default" {...register("is_default")}>
              Set as default pharmacy
            </CheckBox>
          )}

          <div className="flex w-full flex-col justify-center gap-2 md:w-fit md:flex-row-reverse">
            <Button
              className="h-10 w-full md:w-32"
              onClick={handleSubmit((formData) => {
                upsertPharmacy({ formData, pharmacyId: selectedPharmacy?.id });
              })}
              isLoading={isPending}
            >
              Save
            </Button>
            <Button
              className="h-10 w-full md:w-32"
              variant="outline"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
