import { PropsWithChildren, useRef } from "react";
import * as jose from "jose";
import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { Styled } from "~/common/utils";
import { DATA_TOKEN } from "../constants";
import { useGetCurrentPatient } from "../hooks/queries/useGetCurrentPatient";

interface IntroductionCheckinProviderMessageProps
  extends PropsWithChildren,
    Styled {
  greeting: string;
}

export const IntroductionCheckinProviderMessage = ({
  children,
  greeting,
  className,
}: IntroductionCheckinProviderMessageProps) => {
  const { data: user } = useGetCurrentPatient();
  const [searchParams] = useSearchParams();
  let imageSrc = user?.provider.profile_picture_path;
  let teamMember = user?.provider.signature_name;

  const dataToken = searchParams.get(DATA_TOKEN);

  const containerRef = useRef<HTMLDivElement | null>(null);

  if (!user && dataToken) {
    const dataJWT = jose.decodeJwt(dataToken);
    imageSrc = dataJWT.provider_image as string;
    teamMember = dataJWT.provider_name as string;
  }

  return (
    <div
      className={twMerge(
        "relative flex h-full w-full flex-col items-center sm:w-3/5 md:text-base xl:w-3/4",
        className,
      )}
      ref={containerRef}
    >
      <div className="absolute flex max-h-full w-full flex-col gap-4 overflow-y-auto rounded-2xl bg-slate-50 p-4 text-gray-70">
        {children}

        <div className="flex items-center justify-between">
          <p>
            {greeting} <br />
            <strong className="text-base font-semibold">{teamMember}</strong>
          </p>

          {imageSrc && (
            <img
              className="w-1/4 rounded-full"
              src={imageSrc}
              alt="ProfilePicture"
            />
          )}
        </div>
      </div>
    </div>
  );
};
