import { PRONOUNS } from "../constants/constants";

export const pronounsOptions = [
  { value: PRONOUNS.he, label: "He/Him/His" },
  { value: PRONOUNS.she, label: "She/Her/Hers" },
  { value: PRONOUNS.they, label: "They/Them/Theirs" },
];

export const pronounsOptionsWithOther = [
  ...pronounsOptions,
  { value: PRONOUNS.other, label: "Other" },
];
